import React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '@src/hooks/site-metadata';
import { usePageList } from '@src/hooks/page-list';
import { i18nPhone } from '@src/services/strings';
import ogImage from '@src/images/og.jpg';

type HelmetProps = {
  pageId: string;
};

const SEOHelmetFromContent: React.FC<HelmetProps> = ({
  pageId,
}: HelmetProps) => {
  const {
    author,
    title,
    subtitle,
    defaultDescription,
    siteUrl,
    phone,
    email,
  } = useSiteMetadata();
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === pageId);

  const fullTitle = `${
    currentPageData?.title && currentPageData?.title.length > 0
      ? `${currentPageData?.title} | `
      : ''
  }${title} - ${subtitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'fr',
      }}
      title={fullTitle}
      link={[
        {
          rel: 'canonical',
          href: `${siteUrl}${currentPageData?.path}`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: currentPageData?.description || defaultDescription || '',
        },
        {
          property: `og:title`,
          content: fullTitle,
        },
        {
          property: `og:description`,
          content: currentPageData?.description || defaultDescription || '',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${ogImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: siteUrl,
        },
        {
          name: `twitter:image`,
          content: ogImage,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: fullTitle,
        },
        {
          name: `twitter:description`,
          content: currentPageData?.description || defaultDescription || '',
        },
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "WebSite",
            "@id": "${siteUrl}#website",
            "headline": "${title} - ${subtitle}",
            "name": "${title} - ${subtitle}",
            "description": "${defaultDescription}",
            "url": "${siteUrl}"
          }`,
        },
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Person",
            "@id": "${siteUrl}#Person",
            "name": "${title}",
            "url": "${siteUrl}",
            "jobTitle": "${subtitle}",
            "telephone": "${i18nPhone(phone || '')}",
            "email": "${email}",
            "sameAs": ["https://www.linkedin.com/in/ga%C3%ABlle-morat-4079a0170/", "https://www.facebook.com/people/Dr-Morat-V%C3%A9t%C3%A9rinaire-Ost%C3%A9opathie-Phytoth%C3%A9rapie-Hom%C3%A9opathie/61566325957215/"]
          }`,
        },
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "@id": "${siteUrl}#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "item": {
                  "@id": "${siteUrl}",
                  "name": "${title} - ${subtitle}"
                }
              }
              ${
                pageId !== 'home'
                  ? `,{
                "@type": "ListItem",
                "position": 2,
                "item": {
                  "@id": "${siteUrl}${currentPageData?.path}",
                  "name": "${currentPageData?.title}"
                }
              }`
                  : ''
              }
            ]
          }`,
        },
      ]}
    />
  );
};

export default SEOHelmetFromContent;
