import React, { createRef } from 'react';
import SEOHelmetFromContent from '@src/layouts/seo-helmet-from-content';
import Header from './header';
import Footer from './footer';
import Splash from './splash';
import styles from './default.module.scss';
import { debounce } from '@src/services/functions';

const DEBOUNCE_TIMER = 100;

type LayoutProps = {
  pageId: string;
  headerChildren?: React.ReactNode;
  children?: React.ReactNode;
};

type LayoutState = {
  hideTitle: boolean;
  titleHidden: boolean;
  debouncedHandle: ReturnType<typeof debounce> | null;
};

class LayoutFromContent extends React.Component<LayoutProps, LayoutState> {
  state: LayoutState = {
    hideTitle: true,
    titleHidden: true,
    debouncedHandle: null,
  };
  private headerContentRef = createRef<HTMLDivElement>();

  constructor(props: LayoutProps) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    if (this.props.pageId === 'home') {
      this.setState({ hideTitle: true, titleHidden: true });
      await this.setState({
        debouncedHandle: debounce(this.handleScroll, DEBOUNCE_TIMER),
      });

      if (this.state.debouncedHandle !== null) {
        window.addEventListener('scroll', this.state.debouncedHandle);
      }
    } else {
      this.setState({ hideTitle: false, titleHidden: false });
    }
  }

  componentWillUnmount(): void {
    if (this.state.debouncedHandle !== null) {
      window.removeEventListener('scroll', this.state.debouncedHandle);
    }
  }

  isInViewport(element: HTMLDivElement | null): boolean {
    if (!element) return false;
    const height = element.getBoundingClientRect().height;
    const offset = height - 300;
    const top = element.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  handleScroll = (): void => {
    if (this.isInViewport(this.headerContentRef.current) === false) {
      this.setState({ titleHidden: false });
    } else {
      this.setState({ titleHidden: true });
    }
  };

  render(): React.ReactNode {
    const { pageId, headerChildren, children } = this.props;
    const { titleHidden } = this.state;

    return (
      <>
        <div>
          <SEOHelmetFromContent pageId={pageId} />
          <Header pageId={pageId} titleHidden={titleHidden} />
          <main className={styles.main} role="main">
            <div className={styles.mainTop}>
              <Splash pageId={pageId} full={pageId === 'home'} />
              <div ref={this.headerContentRef} className={styles.headerContent}>
                {headerChildren}
              </div>
            </div>
            <div>{children}</div>
          </main>
          <Footer />
        </div>
      </>
    );
  }
}

export default LayoutFromContent;
